import React from 'react'
import '../../App.css'
 import video from '../video-1.mp4'
 import '../HeroSection.css'

 
function Products() {
  return (
    <div className='hero-container'> 
    <video src={video} type="video/mp4" autoPlay loop muted />
    <h1 className='products'>PRODUCTS</h1>
    </div>
  )
}

export default Products