import React from 'react'
import CardItem from './CardItem'
import './Cards.css'
import img from './img-9.jpg'
import img2 from './img-2.jpg'
import img3 from './img-3.jpg'
import img4 from './img-4.jpg'
import img8 from './img-8.jpg'


function Cards() {
  return (
    <div className='cards'>
<h1>Check out these EPIC destinations</h1>
<div className='cards__container'>
  <div className='cards__wrapper'>
   <ul className='cards__items' >
  <CardItem 
    src={img}
    text='Explore the hidden waterfall deep inside the Amazon Jungle'
    label='Adventure'
    path='/services'
  />
   <CardItem 
    src={img2}
    text='Travel through the islands of Bali in a private cruise'
    label='Luxury'
    path='/services'
  />
   </ul>

   <ul className='cards__items' >
  <CardItem 
    src={img3}
    text='Set Sail in the Atlantic Ocean visiting Unchartered Waters'
    label='Adventure'
    path='/services'
  />
   <CardItem 
    src={img4}
    text='Experience Football on top of the Himalayan Mountains'
    label='Luxury'
    path='/services'
  />
  <CardItem 
    src={img8}
    text='Ride through the Sahara Desert on a guided camel tour'
    label='Luxury'
    path='/services'
  />
   </ul>
  </div>
</div>
    </div>
  )
}

export default Cards